import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { get_ajax, post } from '../../../utils/DataProvider';
import { LoadingCircleComponent } from '../../buildingBlocks/LoadingCircleComponent';
import { AiOutlineFile, AiOutlineCloudDownload,  AiFillCaretDown, AiFillCaretUp, AiOutlineFolderOpen, AiOutlineRollback, AiOutlineEllipsis, AiOutlineFolderAdd, AiOutlineCloudUpload, AiOutlineReload, AiFillFolder, AiFillFile, AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { CreateNewFolderModal, EditFolderModal, UploadFileModal } from './ArchiveHelpers';
import axios from 'axios';
import { BsArrowRepeat, BsArchive, BsArrowReturnRight,  } from "react-icons/bs";
import { UncontrolledCollapse } from 'reactstrap';
import { RiDeleteBin5Line, RiCameraFill, RiEdit2Line, RiStarSFill, RiStarSLine, RiLinksFill, RiAttachmentLine, RiImageLine, RiLiveLine } from 'react-icons/ri';
import { DeleteConfirmationModal, UsersViewerModal } from '../Home';
import { translate } from '../../../translations/translator_utils';

const downloadAs = (url, name) => {
    axios.get(url, {
        headers: {
            "Content-Type": "application/octet-stream",
            "Access-Control-Allow-Origin": "*"
        },
        responseType: "blob"
    })
        .then(response => {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(response.data);
            a.href = url;
            a.download = name;
            a.click();
        })
        .catch(err => {
            console.log("error", err);
        });
};

export const Archive = (props) => {
    const [directoryPath, setDirectoryPath] = useState(null);
    const [filePath, setFilePath] = useState(undefined);
    useEffect(() => {
        if (props.match.params.directoryPath != undefined) {
            setDirectoryPath(props.match.params.directoryPath);
        }
    }, [props.match.params.directoryPath]);

    useEffect(() => {
        setFilePath(props.match.params.filePath);
    }, [props.match.params.filePath]);
    
    return (
        <>
            {(window.innerWidth < 900) ? <MobileArchive fileId={filePath} directoryPath={directoryPath} /> :
                <>
                    <div className="row">
                        {/*<div className="col-md-3">*/}
                        {/*    <ArchiveHierarchyView />*/}
                        {/*</div>*/}
                        {/*<div className="col-md-9">*/}
                        {/*    {(filePath == undefined || filePath == null) && (*/}
                        {/*        <ArchiveFolderView directoryId={directoryPath} />*/}
                        {/*    )}*/}
                        {/*    {(filePath != undefined && filePath != null) && (*/}
                        {/*        <FileView fileId={filePath} />*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                </>
            }
        </>
    );
}

const MobileArchive = ({ fileId, directoryPath }) => {
    return (
        <SwipeableViews enableMouseEvents index={1}>
            {/*<ArchiveHierarchyView />*/}
            {/*<>*/}
            {/*    {(fileId == undefined || fileId == null) && (*/}
            {/*        <ArchiveFolderView directoryId={directoryPath} />*/}
            {/*    )}*/}
            {/*    {(fileId != undefined && fileId != null) && (*/}
            {/*        <FileView fileId={fileId} />*/}
            {/*    )}*/}
            {/*</>*/}
        </SwipeableViews>
    );
}

//const ArchiveHierarchyView = ({ directoryId }) => {    
//    const getTreeData = (nestedTreeData) => {
//        return nestedTreeData.map((item) => ({
//            ...item,
//            hasChildren:
//                nestedTreeData.filter((i) => i.parentId === item.id).length > 0,
//        }));
//    }

//    useEffect(() => {
//        loadDirectory();
//    }, [directoryId]);

//    const [foldersList, setFoldersList] = useState([]);

//    const loadDirectory = () => {
//        get_ajax('Archive/ArchiveManager/GetDirectoryOutline', (d_info) => {
//            setFoldersList(getTreeData(d_info));
//        });
//    }
    
//    return (
//        <>
//            <div className="card shadow">
//                <div className="card-body" style={{ marginBottom: '10px' }}>
//                    <h5 className="card-title" style={{ color: '#732E81' }}>{translate('home.archive.title')}</h5>
//                    <div className="archiveHierarchyViewLIST">
//                        <Tree treeData={foldersList} />
//                    </div>
//                </div>
//            </div>
//        </>
//    );
//}

const SearchBox = (props) => {
    useEffect(() => {
        setShouldRedirect(false);
        setValue('');
    }, [props]);

    const [value, setValue] = useState('');
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const executeSearch = () => setShouldRedirect(true);    

    return (
        <div className="form-inline mr-auto mt-2 mt-lg-0 float-right">
            <div className="input-group">
                <input type="text" className="form-control no-outline  form-control-sm" placeholder={translate('generic.search')} value={value} onChange={(e) => { setValue(e.target.value); }}></input>
                <div className="input-group-append">
                    <button className="btn btn-primary my-2 my-sm-0 no-outline btn-sm" type="button" onClick={executeSearch}> {translate('generic.search')}</button>
                </div>
            </div>
            {shouldRedirect && (
                <Redirect to={"/archive/s_result_" + value}/>
            )}
        </div>
    );
}

const ArchiveFolderView = ({ directoryId }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [directoryName, setDirectoryName] = useState(null);
    const [rights, setRights] = useState(undefined);
    const [dInfo, setDInfo] = useState(undefined);

    useEffect(() => {
        loadDirectory();
    }, [directoryId]);

    const loadDirectory = () => {
        get_ajax('Archive/ArchiveManager/GetDirectoryInfo?directoryId=' + directoryId, (d_info) => {            
            if (d_info == undefined) return;
            setDirectoryName(d_info.directoryName);
            setRights(d_info.rights);
            setDInfo(d_info);
            console.log(d_info);
            setIsLoading(false);
        });
    }

    const [isNewFolderModalVisible, setIsNewFolderModalVisible] = useState(false);
    const [isEditFolderModalVisible, setIsEditFolderModalVisible] = useState(false);
    const [isUploadFileDialogVisible, setIsUploadFileDialogVisible] = useState(false);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(undefined);

    return isLoading ? <LoadingCircleComponent/> : (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <SearchBox directoryId={directoryId}/>
                </div>
                <div className="card-body archiveView" style={{ marginBottom: '10px' }}>
                    <h5 className="card-title" style={{ color: '#732E81', display: 'inline' }}>{directoryName}</h5>

                    <div className="btn-toolbar float-right">
                    <div className="btn-group mr-2" role="group">
                            <Link to={"/archive/" + dInfo.directoryParent}>
                            <button type="button" className="btn btn-secondary no-outline">
                                <AiOutlineRollback />
                            </button>
                        </Link>
                    </div>
                    {rights == 2 && (
                        <>                                                        
                                <div className="btn-group mr-2" role="group">
                                    <button type="button" className="btn btn-primary no-outline" onClick={() => { loadDirectory(); }}>
                                        <AiOutlineReload />
                                    </button>
                                </div>
                                <div className="btn-group mr-2" role="group">
                                    <button type="button" className="btn btn-secondary no-outline" onClick={() => { setIsNewFolderModalVisible(true); }}>
                                        <AiOutlineFolderAdd/>
                                    </button>
                                    <button type="button" className="btn btn-secondary no-outline" onClick={() => { setIsUploadFileDialogVisible(true);  }}>
                                        <AiOutlineCloudUpload/>
                                    </button>
                                </div>
                                <div className="btn-group mr-2" role="group">
                                    <button disabled={dInfo.directoryParent == "root"} type="button" className={dInfo.directoryParent == "root" ? "btn btn-primary no-outline disabled" : "btn btn-primary no-outline"}
                                        onClick={() => { setIsEditFolderModalVisible(true); }}
                                    >
                                        <AiOutlineEllipsis/>
                                    </button>
                                </div>
                                <div className="btn-group" role="group">
                                    <button disabled={dInfo.directoryParent == "root"} className="btn btn-warning no-outline" onClick={() => { setIsDeleteDialogOpen(true); }}>
                                        <RiDeleteBin5Line />
                                    </button>
                                </div>                            
                        </>
                        )}
                    </div>
                    <br />
                    <hr />
                    <br />
                    <ul className="list-group">                    
                        {dInfo.children.map((child, index) => (
                            <li className="list-group-item" style={{ padding: '0' }} key={index}>
                                <Link to={child.type == 0 ? "/archive/" + child.id : "/archive_files/" + child.id}>
                                    <div className="fileIcon">
                                        {child.type == 0 && (
                                            <AiFillFolder className="svgICON" />
                                        )}
                                        {child.type == 1 && (
                                            <AiOutlineFile className="svgICON" />
                                        )}                                        
                                        <label>{child.displayName}</label>
                                        {(child.type == 1 && child.hasVersions == false) && (
                                            <button className="btn btn-primary float-right"
                                                onClick={(e) => {
                                                    post('Archive/ArchiveManager/TrackFileDownload', {
                                                        fileId: child.id
                                                    }, (response) => {
                                                        if (response === true)
                                                            downloadAs("https://joycdn-e3a5h7g6hqcdhdbp.z01.azurefd.net/joyhub-datastorage-files/" + child.downloadLink, child.displayName)
                                                        else {
                                                            alert("Eroare la descarcare");
                                                        }
                                                    });                                                    
                                                    e.preventDefault();
                                                }}
                                            >
                                                <AiOutlineCloudDownload/>
                                            </button>
                                        )}
                                    </div>
                                </Link>
                            </li>                        
                        ))}
                    </ul>
                </div>
                {shouldRedirect != undefined && (
                    <Redirect to={shouldRedirect} />
                )}
            </div>
            <CreateNewFolderModal
                isOpen={isNewFolderModalVisible}
                toggle={() => { setIsNewFolderModalVisible(false); }}
                onConfirm={(
                    folderName,

                    read_write_all,
                    read_write_users,
                    read_write_functii,
                    read_write_departamente,
                    read_write_locatii,

                    read_only_all,
                    read_only_users,
                    read_only_functii,
                    read_only_departamente,
                    read_only_locatii
                ) => {
                    post('Archive/ArchiveManager/CreateNewDirectory',
                        {
                            'parentId': directoryId,
                            'newFolderName': folderName,

                            'read_write_all': read_write_all,
                            'read_write_users': JSON.stringify(read_write_users),
                            'read_write_functii': JSON.stringify(read_write_functii),
                            'read_write_departamente': JSON.stringify(read_write_departamente),
                            'read_write_locatii': JSON.stringify(read_write_locatii),

                            'read_only_all': read_only_all,
                            'read_only_users': JSON.stringify(read_only_users),
                            'read_only_functii': JSON.stringify(read_only_functii),
                            'read_only_departamente': JSON.stringify(read_only_departamente),
                            'read_only_locatii': JSON.stringify(read_only_locatii)
                        },
                        (response) => {
                            if (response == false)
                                alert("Eroare la adaugarea directorului");
                            else loadDirectory();
                        }
                    );
                }}
            />
            {isEditFolderModalVisible && (
                <>
                    <EditFolderModal
                        isOpen={isEditFolderModalVisible}
                        toggle={() => { setIsEditFolderModalVisible(false); }}
                        rw_allow_all={dInfo.rw_allow_all}
                        rw_users={dInfo.rw_users}
                        rw_functii={dInfo.rw_functii}
                        rw_departamente={dInfo.rw_departamente}
                        rw_locatii={dInfo.rw_locatii}
                        ro_allow_all={dInfo.ro_allow_all}
                        ro_users={dInfo.ro_users}
                        ro_functii={dInfo.ro_functii}
                        ro_departamente={dInfo.ro_departamente}
                        ro_locatii={dInfo.ro_locatii}
                        folderNameOriginal={dInfo.directoryName}
                        onConfirm={(
                            folderName,

                            read_write_all,
                            read_write_users,
                            read_write_functii,
                            read_write_departamente,
                            read_write_locatii,

                            read_only_all,
                            read_only_users,
                            read_only_functii,
                            read_only_departamente,
                            read_only_locatii
                        ) => {
                            post('Archive/ArchiveManager/UpdateDirectory',
                                {
                                    'directoryId': directoryId,
                                    'newFolderName': folderName,

                                    'read_write_all': read_write_all,
                                    'read_write_users': JSON.stringify(read_write_users),
                                    'read_write_functii': JSON.stringify(read_write_functii),
                                    'read_write_departamente': JSON.stringify(read_write_departamente),
                                    'read_write_locatii': JSON.stringify(read_write_locatii),

                                    'read_only_all': read_only_all,
                                    'read_only_users': JSON.stringify(read_only_users),
                                    'read_only_functii': JSON.stringify(read_only_functii),
                                    'read_only_departamente': JSON.stringify(read_only_departamente),
                                    'read_only_locatii': JSON.stringify(read_only_locatii)
                                },
                                (response) => {
                                    if (response == false)
                                        alert("Eroare la adaugarea directorului");
                                    else loadDirectory();
                                }
                            );
                        }}
                    />
                </>
            )}

            <UploadFileModal
                dinfo={directoryId}
                isOpen={isUploadFileDialogVisible}
                toggle={() => { setIsUploadFileDialogVisible(false); setTimeout(() => { loadDirectory(); }, 5000); }}
                onClick={() => { }}
            />

            <DeleteConfirmationModal
                isOpen={isDeleteDialogOpen}
                toggle={() => { setIsDeleteDialogOpen(!isDeleteDialogOpen) }}
                onConfirm={() => {
                    post('Archive/ArchiveManager/DeleteFolder', {
                        folderId: directoryId
                    }, (response) => {
                        setShouldRedirect("/archive/" + dInfo.directoryParent);
                    });
                }}
            />
        </>
    );
}


export default function Tree({ treeData, parentId = "root", level = 0 }) {    
    const items = treeData
        .filter((item) => item.parentId === parentId)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    if (!items.length) return null;    
    return (
        <>
            {items.map((item) => (
                <div key={item.id} item={item} level={level} className="treeBlock" style={(level != 0 && level != 1) ? { paddingLeft: '15px' } : {}}>
                    <Link to={"/archive/" + item.id} style={{ fontSize: '16' }}>
                        {level != 0 && (
                            <BsArrowReturnRight />
                        )}
                        <AiFillFolder style={{ marginRight: '5px' }} />
                        {item.name}
                    </Link>
                    {item.hasChildren && (
                        <>
                            <button className="btn btn-secondary no-outline float-right" id={parentId + "_" + level + "_" + item.id}>
                                <AiFillCaretDown className="float-right" />
                            </button>
                            <UncontrolledCollapse toggler={"#" + parentId + "_" + level + "_" + item.id} defaultOpen={level == 0} style={{marginTop: '5px'}}>
                                <Tree treeData={treeData} parentId={item.id} level={level + 1} />
                            </UncontrolledCollapse >
                        </>
                    )}                    
                </div>
            ))}
        </>
    );
}



const FileView = ({ fileId }) => {

    const [directoryName, setDirectoryName] = useState('');
    const [directoryId, setDirectoryId] = useState('');

    const [fileName, setFileName] = useState('');
    const [downloadLink, setDownloadLink] = useState('');

    const [rights, setRights] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [createdById, setCreatedById] = useState('');

    const [downloadedBy, setDownloadedBy] = useState([]);
    const [downloadedByDialogData, setDownlaodedByDialogData] = useState([]);
    const [isDownloadedByOpen, setIsDownloadedByOpen] = useState(false);

    const [history, setHistory] = useState([]);

    useEffect(() => {
        loadFile();
    }, [fileId]);

    const loadFile = () => {
        get_ajax('Archive/ArchiveManager/GetFileDetails?fileId=' + fileId, (f_info) => {
            if (f_info == undefined) return;
            setDirectoryName(f_info.directoryName);
            setDirectoryId(f_info.directoryId);

            setFileName(f_info.fileName);
            setDownloadLink(f_info.downloadLink);

            setRights(f_info.rights);

            setCreatedAt(f_info.createdAt);
            setCreatedBy(f_info.createdByDisplayName);
            setCreatedById(f_info.createdById);

            setHistory(f_info.history);

            setDownloadedBy(f_info.downloadedBy);
        });
    }

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);    
    const [shouldRedirect, setShouldRedirect] = useState(undefined);

    const [isUploadFileDialogVisible, setIsUploadFileDialogVisible] = useState(false);

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-body archiveView" style={{ marginBottom: '10px' }}>
                    <h5 className="card-title" style={{ color: '#732E81', display: 'inline' }}>{directoryName} &gt; {fileName}</h5>

                    <div className="btn-toolbar float-right">
                        <div className="btn-group mr-2" role="group">
                            <Link to={"/archive/" + directoryId}>
                                <button type="button" className="btn btn-secondary no-outline">
                                    <AiOutlineRollback />
                                </button>
                            </Link>
                        </div>

                        {rights == 2 && (
                            <>
                                <div className="btn-group" role="group">
                                    <button className="btn btn-warning no-outline" onClick={() => { setIsDeleteDialogOpen(true); }}>
                                        <RiDeleteBin5Line />
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    <hr />                    
                    <label>{translate('archive.uploaded_at')} {createdAt}</label>
                    <br/>
                    <label>{translate('archive.uploaded_by')} <Link to={'/profile/' + createdById}>{createdBy}</Link></label>
                    <br />
                    <button onClick={() => { setDownlaodedByDialogData(downloadedBy); setIsDownloadedByOpen(true); }} className="btn btn-link">{downloadedBy.length} {downloadedBy.length != 1 ? translate('archive.downloads') : translate('archive.download')}</button>
                    <UsersViewerModal isOpen={isDownloadedByOpen} toggle={() => { setIsDownloadedByOpen(!isDownloadedByOpen); }} title="Descarcari" selectedUsersIds={downloadedByDialogData.filter(onlyUnique)} />
                    <br />
                    <br />
                    <button className="btn btn-default btn-primary no-outline" onClick={() => {
                        post('Archive/ArchiveManager/TrackFileDownload', {
                            fileId: fileId
                        }, (response) => {
                            if (response === true)
                                downloadAs("https://joycdn-e3a5h7g6hqcdhdbp.z01.azurefd.net/joyhub-datastorage-files/" + downloadLink, fileName)
                            else {
                                alert("Eroare la descarcare");
                            }
                        });                        
                    }}>
                        Descarca
                        <AiOutlineCloudDownload style={{ marginLeft: '10px' }} />
                    </button>

                    {rights == 2 && (
                        <button className="btn btn-warning btn-info no-outline float-right" onClick={() => {
                            setIsUploadFileDialogVisible(true);
                        }}>
                            Incarca actualizare
                            <AiOutlineCloudUpload style={{ marginLeft: '10px' }} />
                        </button>
                    )}

                    {history.length != 1 && (
                        <>
                            <hr/>
                            <p>Istoric:</p>
                            {history.map((item, index) => (
                                <div key={index}>
                                    <button className="btn btn-default btn-primary no-outline float-right" onClick={() => {

                                        post('Archive/ArchiveManager/TrackFileDownload', {
                                            fileId: item.id
                                        }, (response) => {
                                            if (response === true)
                                                post('Archive/ArchiveManager/TrackFileDownload', {
                                                    fileId: item.id
                                                }, (response) => {
                                                    if (response === true)
                                                        downloadAs("https://joycdn-e3a5h7g6hqcdhdbp.z01.azurefd.net/joyhub-datastorage-files/" + item.downloadLink, item.fileName)
                                                    else {
                                                        alert("Eroare la descarcare");
                                                    }
                                                });
                                            else {
                                                alert("Eroare la descarcare");
                                            }
                                        });                                        
                                    }}>
                                        {translate('archive.download_action')}
                                        <AiOutlineCloudDownload style={{ marginLeft: '10px' }} />
                                    </button>

                                    <h6>{item.fileName}</h6>
                                    <label>{translate('archive.uploaded_at')} {item.createdAt}</label>
                                    <br />
                                    <label>{translate('archive.uploaded_by')} <Link to={'/profile/' + item.createdById}>{item.createdByDisplayName}</Link></label>
                                    <br />
                                    <button onClick={() => { setDownlaodedByDialogData(item.downloadedBy); setIsDownloadedByOpen(true); }} className="btn btn-link">{item.downloadedBy.length} {item.downloadedBy.length != 1 ? translate('archive.downloads') : translate('archive.download')}</button>
                                    <hr />
                                </div>                                
                            ))}
                        </>
                    )}

                    {shouldRedirect != undefined && (
                        <Redirect to={shouldRedirect} />
                        )}
                </div>
            </div>

            <UploadFileModal
                dinfo={fileId}
                isRelatedFile={true}
                isOpen={isUploadFileDialogVisible}
                toggle={() => { setIsUploadFileDialogVisible(false); setTimeout(() => { loadFile(); }, 1000); }}
                onClick={() => { }}
            />
            <DeleteConfirmationModal
                isOpen={isDeleteDialogOpen}
                toggle={() => { setIsDeleteDialogOpen(!isDeleteDialogOpen)}}
                onConfirm={() => {
                    post('Archive/ArchiveManager/DeleteFile', {
                        fileId: fileId
                    }, (response) => {
                        if (response) {
                            setShouldRedirect("/archive/" + directoryId);
                        }
                    });
                }}
            />
        </>
    );
}